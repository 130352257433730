import {useParams} from "react-router";
import {coursesStore} from "../store/coursesStore";
import {Button} from "../controls/button";
import {accountingApi} from "../api/accountingApi";
import {usersStore} from "../store/usersStore";
import {courseApi} from "../api/courseApi";
import styles from "./remainingBalances.scss"
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {logError} from "../errors/errorConsole";
import {Spinner} from "../controls/spinner";
import * as moment from 'moment';
import history from "../history/history";

const expirationTimestamp = new Date("2024-08-15T00:00:00Z").valueOf();

export const RemainingBalances = observer(
  function RemainingBalances() {
    const {courseId} = useParams();
    const [ccysByPayerId, setCcysByPayerId] = useState({});
    
    useEffect(() => {
      loadPurchases();
    }, []);
    
    async function loadPurchases() {
      await coursesStore.reloadCourse(courseId); //Make sure the store [eventually] contains the latest data this course
      const ps = (await courseApi.getPurchasesForCourse(courseId)).sort((p1, p2) => p1.creationTime - p2.creationTime);
      const currenciesByPayerId = {}
      ps.forEach(p => {
        if (p.currency) {
          currenciesByPayerId[p.userId] = currenciesByPayerId[p.userId] || new Set();
          currenciesByPayerId[p.userId].add(p.currency);
        }
      });
      for (let payerId in currenciesByPayerId) {
        currenciesByPayerId[payerId] = Array.from(currenciesByPayerId[payerId]);
      }
      setCcysByPayerId(currenciesByPayerId);
    }
    
    return <div>
      <div className={styles.header}>
        Remaining balances for {coursesStore.coursesById[courseId]?.name} as of {moment(expirationTimestamp).format("DD-MM-YYYY")}
      </div>
      {Object.keys(ccysByPayerId).map(payerId => <div className={styles.remainingBalance}>
        <Balance payerId={payerId} courseId={courseId} balanceCurrencies={ccysByPayerId[payerId]}/>
      </div>)}
    </div>
  }
);


const Balance = observer(
  function ({courseId, payerId, balanceCurrencies}) {
    const [submitting, setSubmitting] = useState(false);
    const course = coursesStore.coursesById[courseId];
    const remainingCredit = course?.unearnedRevenues.remainingCreditPerPayer[payerId];
    const creditPerStudent = course?.unearnedRevenues.remainingCreditPerPayerAndStudent[payerId];
    const hoursPerStudent = course?.unearnedRevenues.remainingHoursPerPayerAndStudent[payerId];

    const [totalAccBalance, setTotalAccBalance] = useState();

    useEffect(() => {
      loadAccountingBalance();
    }, []);
    async function loadAccountingBalance() {
      setTotalAccBalance(await accountingApi.getUnrealizedSalesForPayer(payerId, expirationTimestamp + 1)); // +1 so that it includes also the sales realizations created by this component itself
    }

    console.log("remaining credit per payer and student: ", course?.unearnedRevenues.remainingCreditPerPayerAndStudent);
    console.log("remaining credit per payer and student and month: ", course?.unearnedRevenuesPerMonth.remainingCreditPerPayerStudentAndMonth);
    console.log("purchases per payer and student and month: ", course?.purchasesToSgByMonth.purchasesToSgByPayerStudentAndMonth);
    console.log("purchases per student and month: ", course?.purchasesToSgByMonth.purchasesToSgByStudentAndMonth);
    console.log("remaining hours per payer and student: ", course?.unearnedRevenues.remainingHoursPerPayerAndStudent);
    console.log("paid hours per student: ", course?.paidHoursPerStudent);
    console.log("attended hours per student: ", course?.attendedHoursPerStudent);

    const studentsPaid = Object.keys(creditPerStudent || {});
    
    return (<div>
      <div className={styles.payer}>{usersStore.getUserById(payerId)?.name}</div>
      <div className={styles.grand}><span className={styles.amount}>S${remainingCredit?.toFixed(2)}</span> in OPS (this course)</div>
      <div className={styles.grand}><span className={styles.amount}>S${totalAccBalance?.toFixed(2)}</span> in accounting (all courses) </div>
      {studentsPaid.map(stId =>
        <div className={styles.student}>
          - {usersStore.getUserById(stId)?.name}: <span className={styles.amount}>S${creditPerStudent[stId]?.toFixed(2)}</span> (<span className={styles.numHours}>{hoursPerStudent[stId]}</span> h)
        </div>
      )}
      
      {(balanceCurrencies.length === 1) ?
        <Button onClick={createBalanceExpiration} disabled={submitting}>
          {submitting ? <Spinner/> : `Realize as sale on ${moment(expirationTimestamp).format("DD-MM-YYYY")}`}
        </Button> :
        <div className={styles.currencies}>
          This payer may have balance in more than one currency: {balanceCurrencies.map(c => <span className={styles.currency}>{c}</span>)} Unclaimed balance would have to be realized manually.
        </div>
      }
    </div>);
    
    async function createBalanceExpiration() {
      setSubmitting(true);
      await Promise.all(studentsPaid.map(async stId => {
        if (hoursPerStudent[stId] > 0) {
          try {
            await courseApi.createBalanceExpiration({
              payerId,
              courseId: course._id,
              balanceSgd: creditPerStudent[stId],
              originalBalanceCurrency: balanceCurrencies[0],
              studentId: stId,
              remainingHours: hoursPerStudent[stId],
              expirationTimestamp
            });
          } catch (e) {
            logError(`Failed to realize unclaimed balance of payer ${payerId} as sales for student ${stId} in course ${course._id}`, e);
          }
        }
      }));
      setSubmitting(false);
      history.push(`/courses/${courseId}/students/${studentsPaid[0]}/purchaseHistory`);
    }
  }
);